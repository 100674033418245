<script setup>
const imageLoaded = ref(false)
const current = ref(0)
const isAutoAdvancing = ref(true)
const autoAdvanceInterval = ref(null)
const speed = ref(10000)
const router = useRouter()

const data = [
  {
    title: 'Centar proizvodnje pločastog materijala u Srbiji',
    subtitle: 'Izaberi dekor po svom ukusu i životnom stilu, projektanti će učiniti sve da sprovedu tvoju zamisao u realnost.',
    cta: 'VIDI KOLEKCIJU DEKORA',
    to: '/plocasti-materijali',
    // image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/board.jpg'
    image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/16178758_1857198221184323_2246474972230655117_o.jpg'
    // image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/klisa.jpg',
    // image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/univer-mp.jpg'
  },
  {
    title: 'Uštedi vreme i novac uz našu novu online prodavnicu okova za nameštaj',
    subtitle: 'Sve što ti je potrebno za tvoj projekat pronađi na našem sajtu i naruči sa lakoćom.',
    cta: 'IZABERI OKOV ZA NAMEŠTAJ',
    to: '/okov-za-namestaj',
    image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/testar-2-scaled.jpg'
  },
  {
    title: 'Kuhinjske radne ploče',
    subtitle: 'Izaberi boju i teksturu koja se uklapa sa ostatkom tvoje nove kuhinje',
    cta: 'IZABERI KUHINJSKE RADNE PLOČE',
    to: '/plocasti-materijali/kuhinjske-radne-ploce',
    image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/kuhinjske-ploce.png'
  },
  {
    title: 'Izrada nameštaja',
    subtitle: 'Savremeni koncept opremanja enterijera, stvarajući prostor u kojem se tvoje želje poklapaju sa kreativnošću naših dizajnera',
    cta: 'POGLEDAJ',
    to: '/namestaj',
    image: 'https://stilles.rs/wp-content/uploads/2015/08/SL-30x2.jpg'
  },
  {
    title: 'Univer ploče',
    subtitle: 'Inovativnost dezena',
    cta: 'POGLEDAJ',
    to: '/plocasti-materijali/univer',
    image: 'https://stilles.rs/wp-content/uploads/2015/08/SL-23x2.jpg'
  },
  {
    title: 'Kant trake',
    subtitle: 'Prodaja i kantovanje pločastog materijala',
    cta: 'IZABERI KANT TRAKE',
    to: '/plocasti-materijali/kant-trake',
    image: 'https://stilles.rs/wp-content/uploads/2015/08/kant-traka.jpg'
  }
]
const currentValue = computed(() => data[current.value])

const handlePrev = () => {
  imageLoaded.value = false
  autoAdvanceInterval.value = current.value = (current.value - 1 + data.length) % data.length
}

const handleNext = () => {
  imageLoaded.value = false
  autoAdvanceInterval.value
    = current.value = (current.value + 1) % data.length
}
const startAutoAdvance = () => {
  setInterval(() => {
    if (isAutoAdvancing.value) {
      imageLoaded.value = false
      handleNext()
    }
  }, speed.value)
}

const stopAutoAdvance = () => {
  clearInterval(autoAdvanceInterval.value)
  autoAdvanceInterval.value = null
}

const toggleAutoAdvance = () => {
  isAutoAdvancing.value = !isAutoAdvancing.value
  if (isAutoAdvancing.value && !autoAdvanceInterval.value) {
    startAutoAdvance()
  }
}

onMounted(() => {
  startAutoAdvance()
})
const to = async (x) => {
  stopAutoAdvance()
  await router.push(x)
}
</script>

<template>
  <div class="a">
    <div class="left">
      <div class="left_content">
        <h1 v-text="currentValue.title" />
        <h2 v-text="currentValue.subtitle" />
        <vButton
          :content="currentValue.cta"
          class="cta"
          @click="to(currentValue.to)"
        />
      </div>

      <div class="hero_controls">
        <div class="fx">
          <div class="state">
            {{ current + 1 }}/{{ data.length }}
          </div>
          <div
            class="circle"
            @click="toggleAutoAdvance"
          >
            <vSpinner
              v-if="!imageLoaded"
              :size="32"
            />
            <vIcon
              v-else
              :id="isAutoAdvancing ? 'pause' : 'play'"
            />
          </div>
          <div
            v-if="!isAutoAdvancing"
            class="circle"
            @click="handlePrev"
          >
            <vIcon
              id="prev"
              :size="20"
            />
          </div>
          <div
            v-if="!isAutoAdvancing"
            class="circle"
            @click="handleNext"
          >
            <vIcon
              id="next"
              :size="20"
            />
          </div>
        </div>
      </div>
    </div>
    <NuxtLink
      :to="currentValue.to"
      class="hero"
      :aria-label="currentValue.title"
    >
      <!-- @load="onImageLoad" :loading="imageLoaded" -->
      <vImage
        :src="currentValue.image"
        loading="eager"
        @load="imageLoaded = true"
      />
      <vSpinner
        v-if="!imageLoaded"
        :size="46"
      />
      <div class="overlay" />
    </NuxtLink>
  </div>
</template>

<style scoped>
.a {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 24px;
  padding-bottom: 32px;
}

.left {
  display: flex;
  flex-direction: column;
  grid-column: 1 /span 2;
  height: 100%;
  /* padding-top: 16px; */
}

.fx {
  display: flex;
  gap: 10px;
  align-items: center;

  & .state {
    padding-right: 16px;
    color: var(--color);
    font-size: 24px;
    font-weight: 800;
    font-variant-numeric: tabular-nums;
  }
}

.cta {
  margin-top: 32px;
}

h1 {
  font-weight: 800;
  font-size: 48px;
  /* text-wrap: balance; */
  line-height: 1;
  padding-bottom: 8px;
}

h2 {
  font-size: 24px;
}

.hero {

  grid-column: 3 / -1;
  --radius: 4px;
  height: 0;
  min-height: 512px;
  position: relative;
  background-color: rgb(var(--color-rgb) / .03);
  border-radius: var(--radius);
  color: var(--color);

  & img {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }

  &:deep(.spinner) {
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 23px);
    color: rgb(var(--color-rgb) / .12);
    z-index: 1;
  }
}

.left_content {
  padding-top: 16px;
  flex: 1;
}

.hero_controls {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: var(--brand-color);
  z-index: 2;

}

.circle {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: var(--brand-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: white;

  & svg {
    vertical-align: middle;
  }

  &.loading {
    background-color: transparent;
  }
}

.hero_container {
  position: relative;
  max-width: 1488px;
  margin: 0 auto;
  height: 100%;
  padding: 24px;
  z-index: 3;
  display: flex;
  flex-direction: column;

  & .div {
    margin-top: 48px;
    flex: 1;
    max-width: 700px;
    display: flex;
    /* align-items: center; */
  }
}

@media (max-width: 512px) {
  .circle {
    --size: 36px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('https://media.istockphoto.com/id/1488288536/photo/silhouette-cherry-blossom-shadow-on-white-background-illustration-isolated-transparent-of.jpg?s=612x612&w=0&k=20&c=iUPsmSFqEG1FOYGKprHZL5VdtfQe-3taapK_k2mMBfw='); */
    z-index: 2;
    /* mix-blend-mode: multiply; */
    background-size: 75%;
    background-repeat: no-repeat;
    background: linear-gradient(to bottom right, rgb(0 0 0 / .5) 0%, transparent 100%) 100% no-repeat;
  }

  .a {
    margin-top: 0;
    ;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 500px;
  }

  h1 {
    font-size: 32px;
  }

  .left {
    padding-top: 24px;
    padding-bottom: 16px;
    z-index: 9;
  }

  .left_content {
    color: white;
    /* height: 500px; */
    z-index: 2;
    padding: 24px;
  }

  .fx {
    & .state {
      color: white;
      font-size: 18px;
    }
  }

  .hero {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    min-height: auto;
    border-radius: 0;

    & img {
      border-radius: 0;
    }
  }
  .hero_controls {
    padding-right: 24px;
  }

  .hero_container {

    & .div {
      align-items: flex-start;
    }
  }
}
</style>
