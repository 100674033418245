<script setup>
const config = useRuntimeConfig()

const ID = 112
const mobileMediaQuery = ref(null)

const mobile = useMediaQuery('(max-width: 512px)')
const updateIsMobile = e => mobile.value = e.matches

const { data } = await useFetch(config.public.CMS + '/wp-json/wp/v2/pages/' + ID, {
  pick: ['content', 'title', 'acf', 'excerpt', 'featured'],
  params: {
    acf_format: 'standard'
  }
})
const title = data.value?.acf.title || data.value?.title.rendered
// eslint-disable-next-line no-useless-escape
const description = ((data.value?.acf.description || data.value?.excerpt.rendered) || '').replace(/(<([^>]+)>|\[&hellip\;\])/ig, '')

const meta = {
  title,
  ogTitle: title,
  description,
  ogDescription: description
}

if (data.value?.acf.card) {
  meta.ogImage = data.value?.acf.card
}

useSeoMeta(meta)

onMounted(async () => {
  mobileMediaQuery.value = window.matchMedia('(max-width: 512px)')
  const updateIsMobile = (e) => {
    mobile.value = e.matches
  }
  updateIsMobile(mobileMediaQuery.value)
  mobileMediaQuery.value.addListener(updateIsMobile)
})
onUnmounted(() => mobileMediaQuery.value.removeListener(updateIsMobile))
</script>

<template>
  <div class="wrapper">
    <vHeroSimple image="/images/xxxxxx.jpg" />

    <section v-if="Boolean(data.acf?.plocasti?.length)">
      <vLabel :content="data.acf.label" />
      <vBoards :snap="mobile">
        <vBoard
          v-for="board in data.acf?.plocasti"
          :key="board.id"
          :board="board"
          :snap="mobile"
        />
      </vBoards>
    </section>

    <vPromo />

    <section v-if="Boolean(data.acf?.okovi?.length)">
      <vLabel :content="data.acf.label_2" />
      <div :class="{ snap: mobile }">
        <div class="products">
          <vProduct
            v-for="product in data?.acf.okovi"
            :key="product.id"
            :product="product"
            :snap="mobile"
          />
        </div>
      </div>
    </section>

    <section v-if="Boolean(data.acf?.kuhinjske_radne_ploce?.length)">
      <vLabel :content="data.acf.label_3" />
      <vBoards :snap="mobile">
        <vBoard
          v-for="board in data.acf?.kuhinjske_radne_ploce"
          :key="board.id"
          :board="board"
          :snap="mobile"
        />
      </vBoards>
    </section>

    <vAbout :content="data.content?.rendered" />

    <section class="sale">
      <div>
        <h4>Maloprodaja pločastog materijala i okova u Novom Sadu</h4>
        <vContent :content="data?.acf.maloprodaja" />
        <div class="cta">
          <NuxtLink to="/maloprodaja-plocastog-materijala-i-okova">
            <span>Saznaj više</span>
            <vIcon id="chevron_right" />
          </NuxtLink>
        </div>
      </div>
      <div>
        <h4>Veleprodaja pločastog materijala</h4>
        <vContent :content="data?.acf.veleprodaja" />
        <div class="cta">
          <NuxtLink to="/veleprodaja-plocastog-materijala">
            <span>Saznaj više</span>
            <vIcon id="chevron_right" />
          </NuxtLink>
        </div>
      </div>
    </section>

    <!-- <section class="pt">
      <vContent :content="data.content?.rendered" />
    </section> -->

    <vEdit :id="ID" />
  </div>
</template>

<style scoped>
.wrapper {
  margin-top: 0;
}

.cta {
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  color: rgb(var(--color-rgb) / .5);
  & a {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
  }
}

h4 {
  font-weight: 800;
  font-size: 32px;
  margin-bottom: 8px;
  line-height: 1;
  min-height: 64px;
}

.sale {
  padding-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}

.pt {
  padding-top: 64px;
}

:deep(.content) {
  max-width: 700px;
}

.products {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  margin-bottom: 32px;
}

@media (max-width: 512px) {
  .snap {
    scroll-snap-type: x mandatory;
    overflow: scroll;
    overflow-y: hidden;
    display: flex;
    padding-bottom: 17px;
    box-sizing: content-box;
    margin-left: -24px;
    margin-right: -24px;

    & .products {
      width: auto;
      display: flex;
      margin-left: 24px;
      margin-right: 24px;
      column-gap: 24px;
      row-gap: 16px;
      padding-top: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .products {
    /* margin-bottom: 90px; */
    gap: 24px 16px;
    grid-template-columns: repeat(2, 1fr);

  }

  .sale {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 64px;
  }
}
</style>
